<template>
  <v-card>
    <v-card-text>
      <p>{{ datos.descripcion }}</p>
      <v-row>
        <v-col cols="12">
          <div class="ps-promo">
            <div class="container">
              <div class="row">
                <div class="col-12 col-sm-4" v-for="(item, index) in datosArray" :key="index">
                  <PromoItem :item="item" />
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(item, i) in datosArray" :key="i">
                <v-expansion-panel-header class="my-1">{{ item.title }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="ps-promo ps-not-padding">
                    <div class="container">
                      <div class="row">
                        <div class="col-12 col-sm-4">
                          <PromoItem :item="item" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-btn  class="ml-3" color="error" @click="eliminar(i)" small>ELiminar</v-btn>

                  <v-divider class="my-6"></v-divider>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-textarea dense rows="2" v-model="item.title" outlined label="Titulo"></v-textarea>
                      <SelectTextColorVue
                        class="mb-4"
                        :dense="true"
                        :label="'Color Titulo'"
                        :outlined="true"
                        v-model="item.classTitle"
                      />

                      <v-text-field dense label="Texto Boton" outlined v-model="item.btnName"></v-text-field>
                      <v-text-field dense label="Link Boton" outlined v-model="item.btnLink"></v-text-field>
                      <v-text-field dense label="Etiqueta" outlined v-model="item.badge"></v-text-field>

                      <SelectBtnColor
                        v-model="item.btnClass"
                        :clearable="true"
                        class="mb-4"
                        :dense="true"
                        :outlined="true"
                        :label="'Boton Color Fondo'"
                      />
                      <AddImages
                        :_width="250"
                        :_height="200"
                        :value="item.imgJson"
                        @sendImg="sendImg"
                        :_selected="true"
                        title="Imagen"
                        :_objeto="item"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field dense label="Precio" outlined v-model="item.price"></v-text-field>
                      <SelectTextColorVue
                        class="mb-4"
                        :dense="true"
                        :label="'Precio texto'"
                        :outlined="true"
                        v-model="item.priceColor"
                      />
                      <v-text-field dense label="Precio antes" outlined v-model="item.del"></v-text-field>
                      <SelectTextColorVue
                        class="mb-4"
                        :dense="true"
                        :label="'Precio antes texto'"
                        :outlined="true"
                        v-model="item.delColor"
                      />
                      <v-checkbox label="Precio Horizontal" color="primary" v-model="item.priceHorizontal"></v-checkbox>
                      <v-text-field dense label="Descuento" outlined v-model="item.sale"></v-text-field>
                      <SelectTextColorVue
                        class="mb-4"
                        :dense="true"
                        :label="'Descuento texto'"
                        :outlined="true"
                        v-model="item.saleColor"
                      />

                      <v-text-field dense label="Texto" outlined v-model="item.text"></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-row>
            <v-btn outlined color="primary" @click="agregar" block>Agregar una entrada</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="text-right">
      <v-btn @click="Guardar()" class="primary mt-2">Guardar</v-btn>
    </v-card-text>
  </v-card>
</template>
  
  <script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import PromoItem from './PromoItem.vue'

import AddImages from '@/views/sistema/configuracion/imagen/componentes/AddImages.vue'
import SelectBtnColor from './SelectBtnColor.vue'
import SelectBgColorVue from './SelectBgColor.vue'
import ColorPickerVue from '../../../../../components/ColorPicker.vue'
import SelectTextColorVue from './SelectTextColor.vue'
import config from '@/api/config'
import PromoServices from '@/api/servicios/PromoServices'
import store from '@/store'

import goTo from 'vuetify/lib/services/goto'
export default {
  components: {
    PromoItem,
    SelectBgColorVue,
    ColorPickerVue,
    SelectTextColorVue,
    AddImages,
    SelectBtnColor,
  },
  setup(props, context) {
    const percentPosiciones = [
      {
        text: 'Posicion 1',
        value: 'ps-center',
      },
      {
        text: 'Posicion 2',
        value: 'ps-top',
      },
      {
        text: 'Posicion 3',
        value: 'ps-left',
      },
    ]
    const datos = ref({
      id: 0,
      descripcion: '',
      indActivo: true,
      datos: [],
    })

    const CargarEditar = item => {
      console.log('item.datos', item.datos)
      console.log('item.datos', JSON.parse(item.datos))
      datos.value = { ...item, datos: JSON.parse(item.datos) }
      datosArray.value = JSON.parse(item.datos)
      goTo(0)
    }
    const eliminar = (item) => {
       datosArray.value.splice(item,1)
    }
    const objetoInit = Object.freeze({
      banner: 'http://localhost:4003/img/promotion/bg-banner3.jpg',
      title: 'Super cepillo <br/>X200 higiénico',
      btnName: 'Ir',
      btnLink: '/shop',
      btnClass: 'btn-warning', //warning/yellow/green/white/default
      price: '',
      del: '',
      sale: '10 %',
      classTitle: 'text-white',
      badge: 'Nuevo',
      saleColor: 'text-warning',
      priceHorizontal: false,
      priceColor: 'text-yellow',
      delColor: 'text-secondary',
      text: '',
      image: '',
    })

    const sendImg = datos => {
      console.log('datos', datos)
      if (datos.img.length > 0) {
        datos.objeto.banner = `${config.api_imagenes}${datos.img[0].ruta}`
        datos.objeto.imgJson = datos.img
      } else {
        datos.objeto.banner = 'http://localhost:4003/img/promotion/bg-banner3.jpg'
        datos.objeto.imgJson = []
      }
    }
    onBeforeMount(() => {
      const myJSON = JSON.parse(JSON.stringify(objetoInit))
      datosArray.value = [myJSON]
    })
    const datosArray = ref([])
    const agregar = () => {
      const myJSON = JSON.parse(JSON.stringify(objetoInit))
      datosArray.value.push({ ...myJSON, title: 'Titulo Ejemplo' + (datosArray.value.length + 1) })
    }
    const ActualizarLista = item => {
      context.emit('GetActualizarLista', { ...item })
    }
    const guardando = ref(false)
    const Guardar = () => {
      guardando.value = true

      try {
        PromoServices.Actualizar(store.state.user.idUsuario, {
          ...datos.value,
          datos: JSON.stringify(datosArray.value),
        })
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
              ActualizarLista(response.data.datos.datos)
              datos.value = {
                id: 0,
                descripcion: '',
                indActivo: true,
                datos: [],
              }
            } else {
              store.commit('setAlert', {
                message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            console.log(error)
            store.commit('setAlert', {
              message: error,
              type: 'error',
            })
          })
          .finally(() => {
            guardando.value = false
          })
      } catch (error) {
        //alert(error)
        store.commit('setAlert', {
          message: error,
          type: 'error',
        })
      }
    }
    return {
      datosArray,
      agregar,
      percentPosiciones,
      sendImg,
      CargarEditar,
      datos,
      Guardar,
      ActualizarLista,
      eliminar
    }
  },
}
</script>
