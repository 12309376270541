<template>
  <div class="ps-promo__item">
    <img class="ps-promo__banner" :src="item.banner" alt="alt" />
    <div class="ps-promo__content">
      <span class="ps-promo__badge" v-if="item.badge">{{ item.badge }}</span>
      <h4>
        <span :class="['ps-promo__name ', item.classTitle && item.classTitle]" v-html="item.title"></span>
      </h4>
      <div :class="['ps-promo__sale', item.saleColor && item.saleColor]" v-if="item.sale">{{ item.sale }}</div>
      <div :class="['ps-promo__meta', item.priceHorizontal ? 'd-horizontal' : '']" v-if="item.price">
        <p :class="['ps-promo__price', item.priceColor && item.priceColor]">{{ item.price }}</p>
        <p :class="['ps-promo__del', item.delColor && item.delColor]" v-if="item.del">{{ item.del }}</p>
      </div>
      <div class="ps-promo__icon" v-if="item.text">
        <span>{{ item.text }}</span>
      </div>
      <div class="ps-promo__image" v-if="item.image">
        <img :src="item.image" alt />
      </div>
      <nuxt-link  v-if="item.btnName" :class="['ps-promo__btn', item.btnClass && item.btnClass]" :to="item.btnLink">{{
        item.btnName
      }}</nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss">
$color-1st: rgba(16, 49, 120, 1);
$color-1st-lighter: lighten($color-1st, 20%);
$color-1st-light: lighten($color-1st, 10%);
$color-1st-dark: darken($color-1st, 10%);
$color-1st-darker: darken($color-1st, 20%);

$color-2nd: rgba(255, 200, 0, 1);
$color-2nd-lighter: lighten($color-2nd, 20%);
$color-2nd-light: lighten($color-2nd, 10%);
$color-2nd-dark: darken($color-2nd, 10%);
$color-2nd-darker: darken($color-2nd, 20%);

$color-success: #12a05c;

$color-success-lighter: lighten($color-success, 20%);
$color-success-light: lighten($color-success, 10%);
$color-success-dark: darken($color-success, 10%);
$color-success-darker: darken($color-success, 20%);

$color-info: #84fff7;

$color-info-lighter: lighten($color-info, 20%);
$color-info-light: lighten($color-info, 10%);
$color-info-dark: darken($color-info, 10%);
$color-info-darker: darken($color-info, 20%);

$color-warning: #fd8d27;
$color-warning-bg: #fdf6ed;
$color-warning-border: #ffebd3;

$color-warning-lighter: lighten($color-warning, 20%);
$color-warning-light: lighten($color-warning, 10%);
$color-warning-dark: darken($color-warning, 10%);
$color-warning-darker: darken($color-warning, 20%);

$color-danger: rgba(240, 0, 0, 1);

$color-danger-lighter: lighten($color-danger, 20%);
$color-danger-light: lighten($color-danger, 10%);
$color-danger-dark: darken($color-danger, 10%);
$color-danger-darker: darken($color-danger, 20%);

$color-border: rgba(240, 242, 245, 1);
$color-border1: rgba(217, 222, 232, 1);
$color-border2: rgba(40, 70, 134, 1);
$color-border3: #4b4b4b;
$color-border4: #d9d9d9;

$color-border-lighter: lighten($color-border, 20%);
$color-border-light: lighten($color-border, 10%);
$color-border-dark: darken($color-border, 10%);
$color-border-darker: darken($color-border, 20%);

$color-text: rgba(155, 171, 205, 1);

$color-text-lighter: lighten($color-text, 20%);
$color-text-light: lighten($color-text, 10%);
$color-text-dark: darken($color-text, 10%);
$color-text-darker: darken($color-text, 20%);

$color-contrast: rgba(91, 108, 143, 1);

$color-contrast-lower: darken($color-contrast, 5%);
$color-contrast-low: darken($color-contrast, 17%);
$color-contrast-medium: darken($color-contrast, 52%);
$color-contrast-high: darken($color-contrast, 80%);
$color-contrast-high: darken($color-contrast, 88%);

$color-heading: #222;
$color-dark: #333;
$color-dark1: #182947;
$color-gray: #9babcd;
$color-gray1: rgba(16, 49, 120, 0.1);
$color-gray2: #f4f5f7;
$color-gray4: #ededed;
$color-yellow: #ffc800;
$color-bg: rgba(247, 248, 250, 1);
$color-pink: #752a2b;
$color-green: #00a198;
$color-green1: #00605a;
$color-gray3: #f5f5f5;
$color-primary: #060572;

$color-white: #ffffff;
$color-secondary: #6c757d;

.text-warning {
  color: $color-warning !important;
}
.text-secondary {
  color: $color-secondary !important;
}
.text-white {
  color: $color-white !important;
}

.text-success {
  color: $color-success !important;
}
.text-primary {
  color: $color-primary !important;
}

.text-danger {
  color: $color-danger !important;
}
.text-info {
  color: $color-info !important;
}
.text-yellow {
  color: $color-yellow !important;
}

.ps-promo {
  &__item {
    display: block;
    position: relative;
    overflow: hidden;
    height: 100%;

    &:hover {
      .ps-promo__banner {
        transform: scale(1.1, 1.1);
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 34px;
    color: $color-1st;
    text-align: center;
    font-weight: 600;
  }

  &__text {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translate(0, -50%);
    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);
    z-index: 10;
  }

  &__banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 800ms ease 0s;
    border-radius: 4px;
  }

  &__content {
    position: absolute;
    top: 50%;
    transform: translate(-0, -50%);
    left: 0;
    padding: 25px 30px;
  }

  .ps-promo__badge {
    padding: 1px 10px;
    line-height: 18px;
    display: inline-block;
    text-transform: uppercase;
    color: white;
    background-color: $color-success;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 40px;
    margin-bottom: 5px;
  }

  .ps-promo__name {
    color: $color-1st;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;

    &.mb-20 {
      margin-bottom: 20px;
    }
  }

  .ps-promo__btn {
    display: inline-block;
    background-color: $color-1st;
    color: white;
    padding: 0px 25px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 40px;
    border: 1px solid $color-1st;
    line-height: 26px;

    &:hover {
      background-color: $color-warning;
      border-color: $color-warning;
    }
  }

  .ps-promo__sale {
    color: $color-warning;
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 1.2;
  }

  .ps-promo__price {
    color: $color-yellow;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0;
  }

  .ps-promo__del {
    font-size: 18px;
    color: #9babcd;
    line-height: 30px;
    font-weight: 500;
    text-decoration: line-through;
  }

  .ps-promo__meta {
    margin-bottom: 15px;
    &.d-horizontal {
      display: flex;
    }
  }

  .ps-promo__icon {
    margin-bottom: 25px;
    img {
      width: 24px;
      margin-right: 10px;
    }

    span {
      color: $color-warning;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .ps-promo__image {
    margin-bottom: 25px;

    img {
      max-height: 40px;
    }
  }

  .col-12 {
    padding-bottom: 10px !important;
  }

  &.ps-not-padding {
    .col-12 {
      padding-bottom: 0 !important;
    }
  }

  .text-primary {
    color: $color-1st !important;
  }

  .text-yellow {
    color: $color-yellow;
  }

  .text-warning {
    color: $color-warning !important;
  }

  .btn-warning {
    background-color: $color-warning !important;
    border-color: $color-warning !important;
    &:hover {
      background-color: $color-1st !important;
      border-color: $color-1st !important;
    }
  }

  .btn-yellow {
    background-color: $color-yellow !important;
    border-color: $color-yellow !important;
    &:hover {
      background-color: $color-1st !important;
      border-color: $color-1st !important;
      color: $color-yellow !important;
    }
  }

  .btn-green {
    background-color: $color-green1 !important;
    border-color: $color-green1 !important;

    &:hover {
      background-color: $color-1st !important;
      border-color: $color-1st !important;
    }
  }

  .btn-white {
    background-color: white !important;
    border-color: white !important;
    color: $color-dark;

    &:hover {
      background-color: $color-1st !important;
      border-color: $color-1st !important;
      color: white;
    }
  }

  @media (min-width: 768px) {
    .col-12 {
      padding-bottom: 0 !important;
    }

    &__content {
      padding: 40px;
    }

    .ps-promo__name {
      font-size: 15px;
    }

    .ps-promo__badge {
      font-size: 12px;
    }

    .ps-promo__price,
    .ps-promo__sale {
      font-size: 25px;
    }

    .ps-promo__del {
      font-size: 21px;
    }

    .ps-promo__icon {
      span {
        font-size: 16px;
      }
    }
  }

  @media (min-width: 992px) {
    .ps-promo__icon {
      span {
        font-size: 18px;
      }
    }

    .ps-promo__name {
      font-size: 25px;
    }

    .ps-promo__price,
    .ps-promo__sale {
      font-size: 45px;
    }

    .ps-promo__btn {
      font-size: 16px;
      padding: 3px 30px;
    }
  }

  @media (min-width: 1024px) {
    &__title {
      font-size: 40px;
      line-height: 50px;
    }
  }
}

.ps-promo--mask {
  overflow: hidden;

  .ps-banner {
    background-color: #cccbc9;
    height: auto;
    min-height: 280px !important;

    .ps-banner__title {
      color: $color-heading;
    }

    .ps-banner__btn {
      display: block;
      padding: 0 0 10px 0;
      font-size: 16px;
      color: $color-heading;
    }

    .ps-banner__thumnail {
      .ps-banner__image {
        height: auto;
        max-height: 300px;
      }
    }
    .ps-banner__content {
      height: auto;
    }

    .ps-banner__desc {
      color: $color-heading;
      font-size: 19px;
    }

    &.bg-white {
      .ps-banner__content {
        padding-top: 0;
      }
    }
  }

  @media (min-width: 768px) {
    .ps-banner {
      .ps-banner__btn {
        display: table-cell;
        padding-right: 30px;
      }
      .ps-banner__content {
        padding: 40px 0 40px 40px;
        width: 50%;
        flex: 0 0 50%;
      }

      .ps-banner__thumnail {
        height: auto;
        width: 50%;
        flex: 0 0 50%;
        .ps-banner__image {
          height: 80%;
          max-height: 100%;
          width: auto;
        }
      }

      .row-reverse {
        flex-direction: row-reverse;
      }

      &.bg-white {
        .ps-banner__content {
          padding: 40px 40px 40px 0;
        }

        .ps-banner__thumnail {
          .ps-banner__image {
            height: 100%;
            max-width: 110%;
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .ps-banner {
      .ps-banner__content {
        padding: 100px 0 100px 70px;
      }

      .ps-banner__title {
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 20px;
      }

      .ps-banner__shop {
        font-size: 18px;
        margin-top: 15px;
      }

      .ps-banner__thumnail {
        .ps-banner__image {
          height: 95%;
        }
      }

      .ps-banner__desc {
        font-size: 21px;
      }

      &.bg-white {
        .ps-banner__content {
          padding: 60px 70px 60px 0;
        }

        .ps-banner__thumnail {
          .ps-banner__image {
            max-width: initial;
          }
        }
      }
    }
  }
}

.ps-promo--desc {
  background-color: $color-gray2;
  background-image: linear-gradient(to left, $color-gray2, #f3f7f8 80%);

  .ps-banner {
    height: auto;
    .ps-banner__content {
      height: auto;
    }

    .ps-banner__title {
      margin-bottom: 25px;
    }

    .ps-banner__desc {
      font-size: 16px;
      margin-bottom: 25px;
    }

    .ps-banner__btn {
      font-size: 16px;
      margin-bottom: 5px;
      padding-right: 0;
    }

    .ps-banner__thumnail {
      .ps-banner__image {
        width: calc(100% + 30px);
        margin-left: -15px;
        max-width: none;
      }
    }
  }

  @media (min-width: 768px) {
    .ps-banner {
      .ps-banner__block {
        position: initial;
        &.row-reverse {
          flex-direction: row-reverse;
        }
      }

      .ps-banner__thumnail {
        position: initial;
        .ps-banner__image {
          width: auto;
          margin-left: 0;
          height: 100%;
          top: 0;
          bottom: auto;
          left: 0;
          z-index: 0;
        }
      }

      .ps-banner__content {
        position: relative;
        z-index: 10;
        flex: 0 0 60%;
        width: 60%;
        padding: 80px 0;
      }

      .ps-banner__btn-group {
        display: block;
      }

      .ps-banner__btn {
        margin-bottom: 10px;
      }
    }
  }

  @media (min-width: 992px) {
    .ps-banner {
      .ps-banner__content {
        flex: 0 0 50%;
        width: 50%;
      }
    }
  }

  @media (min-width: 1280px) {
    .ps-banner {
      .ps-banner__desc,
      .ps-banner__btn {
        font-size: 18px;
      }

      .ps-banner__title {
        font-size: 50px;
        line-height: 64px;
      }
    }
  }
}

.ps-promo--section {
  .ps-promo__title {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 20px;
    text-align: center;
    color: $color-1st;
    font-weight: 600;
  }

  .ps-promo__subtitle {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 60px;
    color: $color-contrast;
    text-align: center;
    font-weight: 500;
  }

  .ps-promo__footer {
    text-align: center;

    .ps-btn {
      width: auto;
    }
  }

  @media (min-width: 768px) {
    .ps-promo__title {
      font-size: 40px;
      line-height: 50px;
    }

    .ps-promo__subtitle {
      font-size: 20px;
      line-height: 26px;
    }
  }

  @media (min-width: 1280px) {
    .ps-promo__title {
      font-size: 50px;
      line-height: 60px;
    }

    .ps-promo__subtitle {
      font-size: 24px;
      line-height: 30px;
    }
  }
}
</style>
